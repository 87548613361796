













































































































































































































































































































































































































































import {
  computed,
  defineComponent,
  reactive,
  watch,
  ref,
  onMounted,
  SetupContext,
} from "@vue/composition-api";
import TextInput from "@/components/forms/input/TextInput.vue";
import BillingTypeSelector from "@/components/forms/billingTypeSelector/BillingTypeSelector.vue";
import AutocompleteInput from "@/components/forms/autocomplete/AutocompleteInput.vue";
import CheckboxInput from "@/components/forms/checkbox/CheckboxInput.vue";
import Combobox from "@/components/forms/combobox/Combobox.vue";
import SwitchesInput from "@/components/forms/switch/SwitchesInput.vue";
import { CreateAndModifyBillingAddressFormState } from "@/core/dataTypes/types";
import { userFacade } from "@/store/modules/user/user.facade";
import { commonFacade } from "@/store/modules/common/common.facade";
import { validationRegExp } from "@/core/utils/validation/validationRegExp";

export default defineComponent({
  name: "ModifyBillingDetailsForm",
  components: {
    TextInput,
    BillingTypeSelector,
    AutocompleteInput,
    Combobox,
    CheckboxInput,
    SwitchesInput,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    submitLabel: String,
    loading: Boolean,
    defaultCheckBox: Boolean,
    pageId: {
      type: String,
      default: "",
    },
    billingAddressId: {
      type: [String, Boolean],
      default: false,
    },
  },
  setup(
    props,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { emit, refs }: Omit<SetupContext, "refs"> & { [key: string]: any }
  ) {
    const billingAddressId = ref(props.billingAddressId);
    const formValues = reactive<
      Partial<CreateAndModifyBillingAddressFormState>
    >(props.form);
    const countries = computed(commonFacade.countries);
    const countriesLoading = computed(commonFacade.countriesLoading);
    const publicPlaces = computed(commonFacade.publicPlaces);
    const publicPlacesLoading = computed(commonFacade.publicPlacesLoading);
    const validZIPCode = computed(userFacade.validZIPCode);
    const validZIPCodeLoading = computed(userFacade.validZIPCodeLoading);
    const validVATNumber = computed(userFacade.validVATNumber);
    const validVATNumberLoading = computed(userFacade.validVATNumberLoading);
    const billingAddress = computed(userFacade.billingAddress);
    const invoiceGroupIdIsVisible = ref<boolean>(false);
    const setInitialFormTaxValues = ref<boolean>(true);

    const billingTypeChanged = () => {
      if (formValues.corporation) {
        formValues.vatCountry = countries.value?.length
          ? countries.value[0]
          : undefined;
      } else {
        formValues.vatCountry = undefined;
        formValues.vatNumber = "";
        formValues.invoiceGroupId = "";
        formValues.euVatNumber = "";
        formValues.thirdCountryVatNumber = "";
        formValues.hasNotEUTaxNumber = false;
      }
    };

    const hasNotEUTaxNumberChanged = () => {
      formValues.euVatNumber = "";
      formValues.thirdCountryVatNumber = "";
    };
    const countrySelectionChanged = () => {
      if (formValues.country) {
        userFacade.getValidZIPCode(formValues.country);
      }
    };

    const vatNumberChanged = () => {
      if (refs.form?.fields?.vatNumber?.valid) {
        formValues.invoiceGroupId = "";
      }
    };

    const loadFormData = () => {
      if (billingAddressId.value) {
        Promise.all([
          commonFacade.getCountries(),
          commonFacade.getPublicPlaces(),
        ]).then(() => {
          userFacade.getBillingAddress(billingAddressId.value as string);
        });
      }
    };
    watch(
      () => props.billingAddressId,
      (addressId) => {
        billingAddressId.value = addressId;
        loadFormData();
      }
    );
    const billingAddressWatch = watch(
      () => billingAddress.value,
      (newVal) => {
        const billingAddress = newVal?.billingAddress;
        formValues.corporation = billingAddress?.corporation ? 1 : 0;
        formValues.billingName = billingAddress?.billingName;
        formValues.country = countries.value?.find(
          (country) => country.name === billingAddress?.country
        )?.id;
        formValues.city = billingAddress?.city;
        formValues.zipCode = billingAddress?.zipCode;
        formValues.floor = billingAddress?.floor;
        formValues.door = billingAddress?.door;
        formValues.streetNumber = billingAddress?.streetNumber;
        formValues.street = billingAddress?.streetType
          ? billingAddress?.street
          : "";

        // formValues.streetType = publicPlaces.value?.find((item) => item.name === billingAddress?.streetType)?.name || billingAddress?.streetType;
        formValues.streetType =
          publicPlaces.value?.find(
            (item) => item.name === billingAddress?.streetType
          )?.name || billingAddress?.streetType;
        formValues.vatCountry = billingAddress?.vatCountry
          ? countries.value?.find(
              (country) => country.name === billingAddress.vatCountry
            )
          : undefined;
        // formValues.hasNotEUTaxNumber = (billingAddress?.euVatNumber && formValues.vatCountry?.memberOfEu) || formValues.vatCountry?.id === "H" ? false : true;
        formValues.hasNotEUTaxNumber = !!billingAddress?.thirdCountryVatNumber;
        formValues.defaultBillingAddress = newVal?.defaultBillingAddress;

        billingAddressWatch();
      }
    );
    const firstCountryInit = watch(
      () => formValues.country,
      () => {
        firstCountryInit();
        refs.form.validate();
      }
    );

    watch(
      () => formValues.vatCountry,
      (vatCountry, oldVatCountry) => {
        if (vatCountry !== oldVatCountry) {
          if (vatCountry) {
            userFacade.getValidVATNumberCode(vatCountry.id);
          }
          if (setInitialFormTaxValues.value) {
            const billingValues = billingAddress.value?.billingAddress;
            formValues.vatNumber = billingValues?.vatNumber;
            formValues.invoiceGroupId = billingValues?.invoiceGroupId;
            formValues.euVatNumber = billingValues?.euVatNumber;
            formValues.thirdCountryVatNumber =
              billingValues?.thirdCountryVatNumber;

            setInitialFormTaxValues.value = false;
          } else {
            formValues.vatNumber = "";
            formValues.invoiceGroupId = "";
            formValues.euVatNumber = "";
            formValues.thirdCountryVatNumber = "";
            // formValues.hasNotEUTaxNumber = false;
          }
        }
      }
    );
    watch(
      () => formValues.vatNumber,
      (newVal, oldVal) => {
        if (newVal !== oldVal && formValues.vatNumber) {
          const splitTaxNumber: string[] = formValues.vatNumber.split("-");
          invoiceGroupIdIsVisible.value = splitTaxNumber[1] === "4";
        }
      }
    );
    const submit = () => {
      emit("onSubmit");
    };

    onMounted(() => {
      loadFormData();
    });

    return {
      formValues,
      countries,
      countriesLoading,
      publicPlaces,
      publicPlacesLoading,
      validZIPCode,
      validZIPCodeLoading,
      validVATNumber,
      validVATNumberLoading,
      invoiceGroupIdIsVisible,
      billingAddress,
      validationRegExp,
      countrySelectionChanged,
      vatNumberChanged,
      hasNotEUTaxNumberChanged,
      billingTypeChanged,
      submit,
    };
  },
});
