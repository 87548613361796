
































import { computed, defineComponent, reactive, ref } from "@vue/composition-api";
import router from "@/router";
import { ERoutes } from "@/core/dataTypes/enum";
import { CreateAndModifyBillingAddressFormState } from "@/core/dataTypes/types";
import ModifyBillingDetailsForm from "@/components/forms/ModifyBillingDetailsForm/ModifyBillingDetailsForm.vue";
import { alertFacade } from "@/store/modules/alert/alert.facade";
import { userFacade } from "@/store/modules/user/user.facade";
import { getLanguageStaticRoute } from "@/core/utils/router.util";

export default defineComponent({
  name: "ModifyBillingAddressPage",
  components: {
    ModifyBillingDetailsForm,
  },
  setup(props, { root }) {
    const billingAddressId = ref(root.$route.params.addressId);
    const modifyBillingAddressLoading = computed(
      userFacade.modifyBillingAddressLoading
    );
    const formValues = reactive<
      Partial<CreateAndModifyBillingAddressFormState>
    >({
      corporation: 0,
      billingName: "",
      country: "",
      city: "",
      zipCode: undefined,
      street: "",
      streetNumber: "",
      streetType: "",
      floor: "",
      door: undefined,
      vatCountry: undefined,
      vatNumber: "",
      invoiceGroupId: "",
      euVatNumber: "",
      thirdCountryVatNumber: "",
      defaultBillingAddress: false,
      hasNotEUTaxNumber: false,
    });

    const submit = () => {
      alertFacade.resetAlert();
      userFacade.modifyBillingAddress(formValues);
    };

    const prevStep = () => {
      router.push(getLanguageStaticRoute(ERoutes.PROFILE)).catch((err) => err);
    };
    return {
      billingAddressId,
      modifyBillingAddressLoading,
      formValues,
      prevStep,
      submit,
    };
  },
});
